









import {Component, Vue} from 'vue-property-decorator';
import LoginForm, { LoginEvent } from '../components/LoginForm.vue';
import LoginLogo from '../components/LoginLogo.vue';
import Page from '@/components/Page.vue';
import Card from '@/components/card/Card.vue';
import {CreateSessionRequest} from '@/modules/session/rest/request/CreateSessionRequest.ts';

@Component({
    components: {LoginForm, Page, Card, LoginLogo},
})
export default class Login extends Vue {
    private isLoading: boolean = false;
    private hasError: boolean = false;

    private async login(event: LoginEvent) {
        this.isLoading = true;
        try {
            await this.$store.dispatch('sessionStore/create',
                        CreateSessionRequest.createWith(event.username, event.password));
            this.$router.push('/campaign-listing');
            this.hasError = false;
        } catch {
            this.hasError = true;
        }
        this.isLoading = false;
    }
}
