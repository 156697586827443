import { configuration } from '@/config';
import * as qs from 'qs';

export class CreateSessionRequest {
    public static createWith(username: string, password: string) {
        return new CreateSessionRequest(username, password);
    }

    private constructor(private readonly username: string, private readonly password: string) {}

    public toJson() {
        const request = {
            username: this.username,
            password: this.password,
            grant_type: 'password',
            client_id: configuration.KEYCLOAK_CLIENT_ID,
            client_secret: configuration.KEYCLOAK_CLIENT_SECRET,
        };

        return qs.stringify(request);
    }
}
