















import {Component, Vue, Prop} from 'vue-property-decorator';
import Field from '@/components/Field.vue';
import ErrorMessage from '@/components/ErrorMessage.vue';

export interface LoginEvent {
    username: string;
    password: string;
}

@Component({
    components: {Field, ErrorMessage},
})
export default class LoginForm extends Vue {
    @Prop() private readonly hasError!: boolean;

    private username: string = '';
    private password: string = '';
    private hasSumbitted: boolean = false;
    private isLoading: boolean = false;

    private login() {
        this.hasSumbitted = true;
        if (this.hasUsername && this.hasPassword) {
            const event: LoginEvent = {
                username: this.username,
                password: this.password,
            };

            this.$emit('login', event);
        }
    }

    private onEnter() {
        alert(0);
    }

    get hasUsername() {
        return Boolean(this.username);
    }

    get hasPassword() {
        return Boolean(this.password);
    }
}
